import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
	Breadcrumb,
	Input,
	InputGroup,
	PageTitle,
	Pagination,
	Template,
} from '@components'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Tr from '@components/Tr'
import { leadApi } from '@services/LeadsApi'
import { useExportLeads } from '@store/hooks/useExportLeads'
import Formatters from '@utils/helpers/Formatters'
import { useQuery } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] mt-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function ListLeads() {
	const pages = [{ name: 'Leads', href: '#', current: true }]

	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1

	const { exportLeads } = useExportLeads()

	const [startDate, setStartDate] = useState(query.get('start_date') || '')
	const [endDate, setEndDate] = useState(query.get('start_date') || '')
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = leadApi.useListQuery({
		page,
		startDate,
		endDate,
	})

	const searchCallback = useCallback(() => {
		navigate('/leads?page=1')
	}, [navigate])

	useEffect(() => {
		searchCallback()
	}, [startDate, searchCallback])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Usuários' />
				<Flex className={styles.searchContainer}>
					<InputGroup label='Data inicial' id='startDate'>
						<Input
							type='date'
							maxLength={4}
							onChange={(e) => setStartDate(e.currentTarget.value)}
						/>
					</InputGroup>
					<InputGroup label='Data final' id='endDate'>
						<Input
							type='date'
							maxLength={4}
							onChange={(e) => setEndDate(e.currentTarget.value)}
						/>
					</InputGroup>

					<Button onClick={() => exportLeads({ startDate, endDate })}>
						Exportar leads
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table
						th={[
							{
								label: 'nome',
							},
							{ label: 'e-mail' },
							{ label: 'telefone' },
							{ label: 'CTA' },
							{ label: 'Clicou em' },
						]}
					>
						{data?.data && data?.data?.length > 0 ? (
							data?.data.map(
								({ id: userId, name, email, phone, action, clickDate }) => (
									<Tr key={userId}>
										<Td className='w-[40%] px-6'>
											<Link to={`/usuarios/${userId}`}>
												<Flex className='items-center gap-4'>{name}</Flex>
											</Link>
										</Td>
										<Td>
											<Link to={`/usuarios/${userId}`}>
												<Flex className='items-center gap-4'>{email}</Flex>
											</Link>
										</Td>
										<Td>
											<Link to={`/usuarios/${userId}`}>
												<Flex className='items-center gap-4'>{phone}</Flex>
											</Link>
										</Td>
										<Td>
											<Link to={`/usuarios/${userId}`}>
												<Flex className='items-center gap-4'>{action}</Flex>
											</Link>
										</Td>
										<Td>
											<Link to={`/usuarios/${userId}`}>
												<Flex className='items-center gap-4'>
													{Formatters.createdAt(clickDate)}
												</Flex>
											</Link>
										</Td>
									</Tr>
								)
							)
						) : (
							<EmptyRow tdAmount={3} message='Nenhum usuário encontrado' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />
				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
		</>
	)
}

export default ListLeads
