import React, { useEffect, useMemo } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Select, { SingleValue } from 'react-select'

import { Input, InputGroup, TextEditor } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import FileInput from '@components/FileInput'
import PageTitle from '@components/PageTitle'
import Template from '@components/Template'
import { successMessages } from '@constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { addPromotionalLP } from '@schemas/addPromotionalLP.schema'
import { classesApi } from '@services/ClassesApi'
import { promotionalApi } from '@services/PromotionalLPApi'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	divider: 'mt-[32px] mb-[12px]',
}

type Inputs = {
	promotionalTitle: string
	promotionalSubtitle: string
	promotionalText: string
	promotionalButtonText: string
	promotionalRdStationTag: string
	classId: SingleValue<{ value: number; label: string }>
	promotionalImage: Array<Blob>
}

function EditPromotional() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { id } = useParams<{
		id: string
	}>()
	const baseUrl = `/promocional/editar/${id}`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Página Promocional`, `/promocional`, 1),
			createBreadcrumbLink(`Editar Página Promocional`, baseUrl, 2),
		]
	}, [baseUrl])

	const { data: dataClass } = classesApi.useGetFreeClassesQuery({
		limit: 10000,
	})

	const pages = useCreateBreadcrumb(allPages)
	const { data: promotional } = promotionalApi.useGetActiveQuery()

	const [promotionalText, setPromotionalText] = React.useState<
		string | undefined
	>(promotional?.promotionalText)
	const [editPromotional, { isSuccess }] = promotionalApi.useAddMutation()

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors, touchedFields },
		control,
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addPromotionalLP),
	})

	const onSubmit: SubmitHandler<Inputs> = (inputs) => {
		const newData = {
			...inputs,
		}

		editPromotional({
			...newData,
			promotionalButtonText: inputs.promotionalButtonText,
			promotionalRdStationTag: inputs.promotionalRdStationTag,
			classId: inputs.classId ? inputs.classId.value : 0,
		})
	}

	useEffect(() => {
		if (isSuccess) {
			navigate('/promocional')
			dispatch(notificationCreators.show(successMessages.updatedPromotionalLP))
			reset()
		}
	}, [isSuccess, dispatch, navigate, reset])

	useEffect(() => {
		if (promotional) {
			setValue('promotionalTitle', promotional.promotionalTitle)
			setValue('promotionalSubtitle', promotional.promotionalSubtitle)
			setValue('promotionalText', promotional.promotionalText)
			setValue('classId', {
				value: promotional.classId,
				label: promotional.class?.name,
			})
			setPromotionalText(promotional.promotionalText)
			setValue('promotionalButtonText', promotional.promotionalButtonText)
			setValue('promotionalRdStationTag', promotional.promotionalRdstationTag)
		}
	}, [promotional, setValue])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Editar Página Promocional' />

				<form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
					<div className='grid grid-cols-1 gap-4'>
						<InputGroup label='Título da página' id='promotionalTitle'>
							<Input
								type='text'
								{...register('promotionalTitle')}
								isValid={inputIsValid(
									errors.promotionalTitle,
									touchedFields.promotionalTitle
								)}
							/>
						</InputGroup>

						<InputGroup label='Subtítulo da página' id='promotionalSubtitle'>
							<Input
								type='text'
								{...register('promotionalSubtitle')}
								isValid={inputIsValid(
									errors.promotionalSubtitle,
									touchedFields.promotionalSubtitle
								)}
							/>
						</InputGroup>
						<InputGroup label='Texto do botão' id='promotionalSubtitle'>
							<Input
								type='text'
								{...register('promotionalButtonText')}
								isValid={inputIsValid(
									errors.promotionalSubtitle,
									touchedFields.promotionalSubtitle
								)}
							/>
						</InputGroup>
						<InputGroup label='Tag RDStation' id='promotionalSubtitle'>
							<Input
								type='text'
								{...register('promotionalRdStationTag')}
								isValid={inputIsValid(
									errors.promotionalSubtitle,
									touchedFields.promotionalSubtitle
								)}
							/>
						</InputGroup>

						<Controller
							{...register('promotionalText')}
							name='promotionalText'
							control={control}
							render={({ field: { onBlur } }) => (
								<InputGroup
									className={`mt-6 index-0-controler ${
										errors.promotionalText && 'error-textBox'
									}`}
									label='Texto Promocional'
									id='promotionalText'
								>
									<TextEditor
										onChange={(e) => {
											setPromotionalText(e)
											setValue('promotionalText', e)
										}}
										value={promotionalText}
										onBlur={onBlur}
									/>
								</InputGroup>
							)}
						/>

						<InputGroup className='mt-6' label='Turma de amostra' id='classId'>
							<Controller
								control={control}
								name='classId'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('classId', v)}
											options={
												dataClass?.data
													? dataClass.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value}
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder='Turma'
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
							{/* <SelectInput
								options={
									dataClass?.data
										? dataClass.data.map((item) => ({
												value: item.id,
												option: item.name,
										  }))
										: []
								}
								{...register('classId')}
								onChange={(v) => setValue('classId', Number(v.value))}
								btnClass='px-[13px] py-[8px] w-[150px]'
							/> */}
						</InputGroup>

						<Controller
							control={control}
							name='promotionalImage'
							render={() => {
								return (
									<FileInput
										className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]'
										mt='mt-4'
										accept='.png'
										onChange={(v) => {
											setValue('promotionalImage', v)
										}}
									>
										Imagem da página promocional
									</FileInput>
								)
							}}
						/>
					</div>

					<div className='flex gap-4 mt-6 mb-10'>
						<Button variant='outline' onClick={() => navigate('/promocional')}>
							Cancelar
						</Button>
						<Button type='submit'>Editar página promocional</Button>
					</div>
				</form>
				<Divider className={styles.divider} />
			</Template>
		</>
	)
}

export default EditPromotional
