import { BASE_URL } from '@constants/api'
import { ListLeadRequest, ListLeadResponse } from '@models/leads.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const leadApi = createApi({
	reducerPath: 'leadApi',
	tagTypes: ['Usuarios', 'Usuario', 'LeadsPopups'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListLeadResponse, ListLeadRequest>({
			query: ({ page, startDate, endDate }) => ({
				url: `/leads?limit=20&page=${page}&startDate=${startDate}&endDate=${endDate}`,
				method: 'GET',
			}),
			providesTags: ['Usuarios'],
		}),
		listLeadsPopups: builder.query<
			ListLeadResponse,
			{ page: number; startDate?: string; endDate?: string; popupId?: string }
		>({
			query: ({ page, startDate, endDate, popupId }) => ({
				url: `/popup-trackings/?page=${page}&startDate=${
					startDate || ''
				}&endDate=${endDate || ''}&popupId=${popupId || ''}`,
				method: 'GET',
			}),
			providesTags: ['LeadsPopups'],
		}),
		exportLeadsPopups: builder.mutation<
			Blob,
			{ startDate: string; endDate: string }
		>({
			query: ({ startDate, endDate }) => ({
				url: `/popup-trackings/export/?startDate=${startDate}&endDate=${endDate}`,
				method: 'GET',
				responseHandler: (response) => response.blob(),
			}),
		}),
		show: builder.mutation<{ id: string }, { id: string }>({
			query: ({ id }) => ({
				url: `/admins/${id}`,
				method: 'GET',
			}),
		}),
	}),
})

export const { useListQuery, useShowMutation, useExportLeadsPopupsMutation } =
	leadApi
