import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { announcementApi } from '@services/AnnouncementApi'
import { announcementClassApi } from '@services/AnnouncementClassApi'
import { authApi } from '@services/AuthApi'
import { bancaApi } from '@services/BancaApi'
import { categoryApi } from '@services/CategoryApi'
import { classesApi } from '@services/ClassesApi'
import { clientApi } from '@services/ClientApi'
import { commentsModuleApi } from '@services/commentsModuleApi'
import { commentsQuestionsApi } from '@services/commentsQuestionsApi'
import { dashboardApi } from '@services/Dashboard'
import { disciplineApi } from '@services/DisciplineApi'
import { errorsModuleApi } from '@services/errosModuleApi'
import { errorsQuestionsApi } from '@services/errosQuestionsApi'
import { examsApi } from '@services/ExamsApi'
import { questionGeneratorApi } from '@services/GenerateQuestions'
import { institutionApi } from '@services/InstitutionApi'
import { leadApi } from '@services/LeadsApi'
import { librariresApi } from '@services/LibrarieApi'
import { moduleApi } from '@services/ModuleApi'
import { occupationApi } from '@services/OccupationApi'
import { pdfApi } from '@services/PdfApi'
import { popupsApi } from '@services/PopupsApi'
import { promotionalApi } from '@services/PromotionalLPApi'
import { questionsApi } from '@services/QuestionsApi'
import { statusMigrationsApi } from '@services/StatusMigrationsApi'
import { unreadedMessagesApi } from '@services/unreadedMessagesApi'
import { userApi } from '@services/UserApi'
import { yearApi } from '@services/YearApi'

import { filtersApi } from '../services/filtersApi'
import { authReducer } from './ducks/auth'
import { modalReducer } from './ducks/modal'
import { notificationReducer } from './ducks/notification'
import { sidebarReducer } from './ducks/sidebar'
import { errorMiddleware } from './middlewares/error'
import { loggerMiddleware } from './middlewares/logger'

export const store = configureStore({
	reducer: {
		auth: authReducer,
		[authApi.reducerPath]: authApi.reducer,
		[disciplineApi.reducerPath]: disciplineApi.reducer,
		[institutionApi.reducerPath]: institutionApi.reducer,
		[bancaApi.reducerPath]: bancaApi.reducer,
		[yearApi.reducerPath]: yearApi.reducer,
		[occupationApi.reducerPath]: occupationApi.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[leadApi.reducerPath]: leadApi.reducer,
		[clientApi.reducerPath]: clientApi.reducer,
		[classesApi.reducerPath]: classesApi.reducer,
		[librariresApi.reducerPath]: librariresApi.reducer,
		[categoryApi.reducerPath]: categoryApi.reducer,
		[examsApi.reducerPath]: examsApi.reducer,
		[errorsQuestionsApi.reducerPath]: errorsQuestionsApi.reducer,
		[commentsQuestionsApi.reducerPath]: commentsQuestionsApi.reducer,
		[errorsModuleApi.reducerPath]: errorsModuleApi.reducer,
		[commentsModuleApi.reducerPath]: commentsModuleApi.reducer,
		[unreadedMessagesApi.reducerPath]: unreadedMessagesApi.reducer,
		[moduleApi.reducerPath]: moduleApi.reducer,
		[popupsApi.reducerPath]: popupsApi.reducer,
		[pdfApi.reducerPath]: pdfApi.reducer,
		[announcementApi.reducerPath]: announcementApi.reducer,
		[announcementClassApi.reducerPath]: announcementClassApi.reducer,
		[questionsApi.reducerPath]: questionsApi.reducer,
		[questionGeneratorApi.reducerPath]: questionGeneratorApi.reducer,
		[filtersApi.reducerPath]: filtersApi.reducer,
		[dashboardApi.reducerPath]: dashboardApi.reducer,
		[statusMigrationsApi.reducerPath]: statusMigrationsApi.reducer,
		[promotionalApi.reducerPath]: promotionalApi.reducer,

		modal: modalReducer,
		sidebar: sidebarReducer,
		notification: notificationReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false })
			.concat(
				authApi.middleware,
				disciplineApi.middleware,
				bancaApi.middleware,
				institutionApi.middleware,
				yearApi.middleware,
				occupationApi.middleware,
				userApi.middleware,
				leadApi.middleware,
				clientApi.middleware,
				classesApi.middleware,
				librariresApi.middleware,
				categoryApi.middleware,
				examsApi.middleware,
				errorsQuestionsApi.middleware,
				commentsQuestionsApi.middleware,
				errorsModuleApi.middleware,
				commentsModuleApi.middleware,
				unreadedMessagesApi.middleware,
				moduleApi.middleware,
				popupsApi.middleware,
				pdfApi.middleware,
				announcementApi.middleware,
				announcementClassApi.middleware,
				questionsApi.middleware,
				questionGeneratorApi.middleware,
				filtersApi.middleware,
				dashboardApi.middleware,
				statusMigrationsApi.middleware,
				promotionalApi.middleware
			)
			.concat(errorMiddleware, loggerMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
