import { FeedbackMessage } from '@models/feedbackMessage.model'

const successes = {
	storedDiscipline: {
		title: 'Disciplina adicionada',
		message: 'Sua disciplina foi adicionada',
	},
	updatedDiscipline: {
		title: 'Disciplina editada',
		message: 'Sua disciplina foi editada',
	},
	duplicatedDiscipline: {
		title: 'Disciplina duplicada',
		message: 'Sua disciplina foi duplicada',
	},
	deleteDiscipline: {
		title: 'Disciplina removida',
		message: 'Sua disciplina foi removida',
	},
	storedSubject: {
		title: 'Assunto adicionado',
		message: 'Seu assunto foi adicionado',
	},
	updatedSubject: {
		title: 'Assunto editado',
		message: 'Seu assunto foi editado',
	},
	duplicatedSubject: {
		title: 'Assunto duplicado',
		message: 'Seu assunto foi duplicado',
	},
	deleteSubject: {
		title: 'Assunto removido',
		message: 'Seu assunto foi removido',
	},
	storedSubSubject: {
		title: 'Sub Assunto adicionado',
		message: 'Seu sub assunto foi adicionado',
	},
	updatedSubSubject: {
		title: 'Sub Assunto editado',
		message: 'Seu sub assunto foi editado',
	},
	duplicatedSubSubject: {
		title: 'Sub Assunto duplicado',
		message: 'Seu sub assunto foi duplicado',
	},
	deleteSubSubject: {
		title: 'Sub Assunto removido',
		message: 'Seu sub assunto foi removido',
	},
	storedQuiz: {
		title: 'Questão adicionada',
		message: 'Sua questão foi adicionada',
	},
	updatedQuiz: {
		title: 'Questão editada',
		message: 'Sua questão foi editada',
	},
	deleteQuiz: {
		title: 'Questão removida',
		message: 'Sua questão foi removida',
	},
	storedBanca: {
		title: 'Banca adicionada',
		message: 'Sua banca foi adicionada',
	},
	updatedBanca: {
		title: 'Banca editada',
		message: 'Sua banca foi editada',
	},
	deleteBanca: {
		title: 'Banca removida',
		message: 'Sua banca foi removida',
	},
	storedInstitution: {
		title: 'Instituição adicionada',
		message: 'Sua instituição foi adicionada',
	},
	updatedInstitution: {
		title: 'Instituição editada',
		message: 'Sua instituição foi editada',
	},
	deleteInstitution: {
		title: 'Instituição removida',
		message: 'Sua instituição foi removida',
	},
	storedYear: {
		title: 'Ano adicionado',
		message: 'Seu ano foi adicionado',
	},
	updatedYear: {
		title: 'Ano editado',
		message: 'Seu ano foi editado',
	},
	deleteYear: {
		title: 'Ano removida',
		message: 'Seu ano foi removido',
	},
	storedOccupation: {
		title: 'Cargo adicionado',
		message: 'Seu cargo foi adicionado',
	},
	updatedOccupation: {
		title: 'Cargo editado',
		message: 'Seu cargo foi editado',
	},
	deleteOccupation: {
		title: 'Cargo removido',
		message: 'Seu cargo foi removido',
	},
	storedUser: {
		title: 'Usuário adicionado',
		message: 'Seu usuário foi adicionado',
	},
	updatedUser: {
		title: 'Usuário editado',
		message: 'Seu usuário foi editado',
	},
	deleteUser: {
		title: 'Usuário removido',
		message: 'Seu usuário foi removido',
	},
	storedCommunication: {
		title: 'Comunicado adicionado',
		message: 'Seu comunicado foi adicionado',
	},
	updatedCommunication: {
		title: 'Comunicado editado',
		message: 'Seu comunicado foi editado',
	},
	deleteCommunication: {
		title: 'Comunicado removido',
		message: 'Seu comunicado foi removido',
	},
	storedCommunicationClass: {
		title: 'Comunicado Turma adicionado',
		message: 'Seu comunicado foi adicionado',
	},
	updatedCommunicationClass: {
		title: 'Comunicado Turma editado',
		message: 'Seu comunicado foi editado',
	},
	deleteCommunicationClass: {
		title: 'Comunicado removido',
		message: 'Seu comunicado foi removido',
	},
	storedStudent: {
		title: 'Aluno adicionado',
		message: 'Seu aluno foi adicionado',
	},
	updatedStudent: {
		title: 'Aluno editado',
		message: 'Seu aluno foi editado',
	},
	deleteStudent: {
		title: 'Aluno removido',
		message: 'Seu aluno foi removido',
	},
	storedClasses: {
		title: 'Turma adicionada',
		message: 'Sua turma foi adicionada',
	},
	updatedClasses: {
		title: 'Turma editada',
		message: 'Sua turma foi editada',
	},
	deleteClasses: {
		title: 'Turma removida',
		message: 'Sua turma foi removida',
	},
	storedModule: {
		title: 'Módulo adicionado',
		message: 'Seu módulo foi adicionado',
	},
	updatedModule: {
		title: 'Módulo editado',
		message: 'Seu módulo foi editado',
	},
	duplicatedModule: {
		title: 'Módulo duplicado',
		message: 'Seu módulo foi duplicado',
	},
	deleteModule: {
		title: 'Módulo removido',
		message: 'Seu módulo foi removido',
	},
	storedClient: {
		title: 'Cliente adicionado',
		message: 'Seu cliente foi adicionado',
	},
	updatedClient: {
		title: 'Cliente editado',
		message: 'Seu cliente foi editado',
	},
	deleteClient: {
		title: 'Cliente removido',
		message: 'Seu cliente foi removido',
	},
	storedCategory: {
		title: 'Categoria adicionada',
		message: 'Sua categoria foi adicionada',
	},
	updatedCategory: {
		title: 'Categoria editada',
		message: 'Sua categoria foi editada',
	},
	deleteCategory: {
		title: 'Categoria removida',
		message: 'Sua categoria foi removida',
	},
	storedExams: {
		title: 'Simulado adicionado',
		message: 'Seu simulado foi adicionado',
	},
	updatedExams: {
		title: 'Simulado editado',
		message: 'Seu simulado foi editado',
	},
	deleteExams: {
		title: 'Simulado removido',
		message: 'Seu simulado foi removido',
	},
	updatedErrorQuestion: {
		title: 'Erro respondido',
		message: 'Este erro foi respondido',
	},
	updatedStatusErrorQuestion: {
		title: 'Status de correção',
		message: 'Status de correção alterado',
	},
	updatedStatusComentModule: {
		title: 'Status de aprovação',
		message: 'Status de aprovação alterado',
	},
	deleteErrorQuestion: {
		title: 'Erro removido',
		message: 'Este erro foi removido',
	},
	updatedErrorModule: {
		title: 'Erro respondido',
		message: 'Este erro foi respondido',
	},
	updatedStatusErrorModule: {
		title: 'Status de correção',
		message: 'Status de correção alterado',
	},
	deleteErrorModule: {
		title: 'Erro removido',
		message: 'Este erro foi removido',
	},
	storedPopups: {
		title: 'Popup adicionado',
		message: 'Seu popup foi adicionado',
	},
	deletePopups: {
		title: 'Popup removido',
		message: 'Seu popup foi removido',
	},
	storedLibrarie: {
		title: 'Biblioteca adicionada',
		message: 'Sua biblioteca foi adicionada',
	},
	updatedLibrarie: {
		title: 'Biblioteca editada',
		message: 'Esta biblioteca foi editada',
	},
	deleteLibrarie: {
		title: 'Biblioteca removida',
		message: 'Sua biblioteca foi removida',
	},
	storedPDF: {
		title: 'PDF adicionado',
		message: 'Seu pdf foi adicionado',
	},
	updatedPDF: {
		title: 'PDF editado',
		message: 'Seu pdf foi editado',
	},
	deletePDF: {
		title: 'PDF removido',
		message: 'Sua pdf foi removido',
	},
	deleteAnnouncement: {
		title: 'Comunicado removido',
		message: 'Seu comunicado foi removido',
	},
	storedAnnouncement: {
		title: 'Comunicado adicionado',
		message: 'Seu comunicado foi adicionado',
	},
	updatedAnnouncement: {
		title: 'Comunicado editado',
		message: 'Seu comunicado foi editado',
	},
	deleteAnnouncementClass: {
		title: 'Comunicado de turma removido',
		message: 'Seu comunicado de turma foi removido',
	},
	storedAnnouncementClass: {
		title: 'Comunicado de turma adicionado',
		message: 'Seu comunicado de turma foi adicionado',
	},
	updatedAnnouncementClass: {
		title: 'Comunicado de turma editado',
		message: 'Seu comunicado de turma foi editado',
	},
	deleteContent: {
		title: 'Conteudo removido',
		message: 'Seu Conteudo foi removido',
	},
	storedContent: {
		title: 'Conteudo adicionado',
		message: 'Seu Conteudo foi adicionado',
	},
	updatedContent: {
		title: 'Conteudo editado',
		message: 'Seu Conteudo foi editado',
	},
	migrateData: {
		title: 'Migração iniciada',
		message: 'A migração foi iniciada com sucesso',
	},
	giveAccess: {
		title: 'Acesso concedido',
		message: 'Acesso concedido com sucesso, nova senha: migrei123',
	},
	storedGenerateQuestion: {
		title: 'Questão gerada',
		message: 'A questão foi gerada',
	},
	storedGenerateQuestionApproved: {
		title: 'Questão aprovada',
		message: 'A questão foi aprovada',
	},
	storedGenerateQuestionReject: {
		title: 'Questão reprovada',
		message: 'A questão foi reprovada',
	},
	storedPromotionalLP: {
		title: 'Página Promocional adicionada',
		message: 'Sua página promocional foi criada e ativada',
	},
	updatedPromotionalLP: {
		title: 'Página Promocional editada',
		message: 'Sua Página Promocional foi editada',
	},
	deletePromotionalLP: {
		title: 'Página Promocional removida',
		message: 'Sua Página Promocional foi removida',
	},
	activatePromotionalLP: {
		title: 'Página Promocional ativada',
		message: 'Sua Página Promocional foi ativada',
	},
	generatedQuestions: {
		title: 'As questões estão sendo geradas',
		message: 'Aguarde alguns minutos enquanto as questões são geradas',
	},
}

export const successMessages: FeedbackMessage<keyof typeof successes> = {
	...successes,
}

const errors = {
	login: {
		title: 'Ocorreu um erro',
		message:
			'Caso o erro persista, por favor entre em contato com a engenharia.',
		buttonLabel: 'Tentar novamente',
	},
}

export const errorMessages: FeedbackMessage<keyof typeof errors> = {
	...errors,
}
