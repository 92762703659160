import { useCallback } from 'react'

import { BASE_URL } from '@constants/api'
import { openErrorModal } from '@store/ducks/modal'
import { useAppDispatch } from '@store/hooks'
import { api } from '@utils/api'

export function useExportLeads() {
	const dispatch = useAppDispatch()

	const exportLeads = useCallback(
		({ startDate, endDate }: { startDate: string; endDate: string }) => {
			api({
				url: `${BASE_URL}/leads/export?startDate=${startDate}&endDate=${endDate}`, // your url
				method: 'GET',
				responseType: 'blob', // important
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')

					link.href = url
					link.setAttribute('download', `leads.xlsx`)
					document.body.appendChild(link)
					link.click()
					link.remove()
				})
				.catch(() => {
					dispatch(
						openErrorModal({
							title: 'Ocorreu um erro',
							message: 'Ocorreu um erro ao exportar leads',
						})
					)
				})
		},
		[dispatch]
	)

	return { exportLeads }
}
