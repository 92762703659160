import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import Select, { MultiValue } from 'react-select'

import { FileInput, Input, InputGroup } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import Template from '@components/Template'
import { successMessages } from '@constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { questionGeneratorSchema } from '@schemas/questionGenerator.schema'
import { bancaApi } from '@services/BancaApi'
import { disciplineApi } from '@services/DisciplineApi'
import { filtersApi } from '@services/filtersApi'
import { questionGeneratorApi } from '@services/GenerateQuestions'
import { institutionApi } from '@services/InstitutionApi'
import { occupationApi } from '@services/OccupationApi'
import { yearApi } from '@services/YearApi'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

type Inputs = {
	disciplineSelect: MultiValue<{ value: number; label: string }>
	subjectSelect: MultiValue<{ value: number; label: string }>
	subSubjectSelect: MultiValue<{ value: number; label: string }>
	yearSelect?: MultiValue<{ value: number; label: string }>
	occupationSelect?: MultiValue<{ value: number; label: string }>
	bancaSelect?: MultiValue<{ value: number; label: string }>
	institutionSelect?: MultiValue<{ value: number; label: string }>
	sourceFile: File
	quantity: number
	statementInformation: string
	customPrompt: string
}

const styles = {
	divider: 'mt-[32px] mb-[12px]',
}

function QuestionGenerator() {
	const dispatch = useAppDispatch()

	const [disciplineId, setDisciplineId] = useState<string>()
	const [subjectId, setSubjectId] = useState<string>()

	const baseUrl = `/gerador-questoes`

	const allPages = useMemo(() => {
		return [createBreadcrumbLink(`Gerador de Questões`, baseUrl, 1)]
	}, [baseUrl])

	const pages = useCreateBreadcrumb(allPages)

	const [generateQuestion, { isSuccess }] =
		questionGeneratorApi.useGenerateMutation()

	useEffect(() => {
		if (isSuccess) {
			dispatch(notificationCreators.show(successMessages.generatedQuestions))
		}
	}, [isSuccess, dispatch])

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, touchedFields },
		control,
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(questionGeneratorSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const subSubject = data?.subSubjectSelect?.map((r) => {
			return r.value
		})
		const subject = data?.subjectSelect?.map((r) => {
			return r.value
		})
		const year = data?.yearSelect?.map((r) => {
			return r.value
		})

		const position = data?.occupationSelect?.map((r) => {
			return r.value
		})

		const jury = data?.bancaSelect?.map((r) => {
			return r.value
		})

		const institution = data?.institutionSelect?.map((r) => {
			return r.value
		})
		const discipline = data?.disciplineSelect?.map((r) => {
			return r.value
		})
		const dataQuestion = {
			title: data.statementInformation,
			customPrompt: data.customPrompt,
			submatterIds: subSubject || [],
			subjectIds: subject || [],
			yearIds: year || [],
			positionIds: position || [],
			juryIds: jury || [],
			institutionIds: institution || [],
			disciplineIds: discipline || [],
			archive: data.sourceFile,
			quantity: data.quantity,
		}

		generateQuestion(dataQuestion)
		reset()
	}

	const { data: dataDiscipline } = disciplineApi.useListQuery({
		page: 1,
		limit: 100000,
		search: '',
	})

	const { data: dataSubject } = filtersApi.useListMatterQuery({
		disciplineId: disciplineId || '-1',
	})
	const { data: dataSubSubject } = filtersApi.useListSubMatterQuery({
		matterId: subjectId || '-1',
	})

	const { data: dataYear } = yearApi.useListQuery({
		page: 1,
		limit: 100000,
		search: '',
	})
	const { data: dataOccupation } = occupationApi.useListQuery({
		page: 1,
		limit: 100000,
		search: '',
	})

	const { data: dataBanca } = bancaApi.useListQuery({
		page: 1,
		limit: 100000,
		search: '',
	})

	const { data: dataInstitution } = institutionApi.useListQuery({
		page: 1,
		limit: 100000,
		search: '',
	})

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Gerador de Questões' />

				<form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
					<div className='grid grid-cols-2 gap-4'>
						<InputGroup label='Disciplina' id='disciplineSelect'>
							<Controller
								control={control}
								name='disciplineSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => {
												setDisciplineId(v?.map((val) => val.value).join(','))
												setValue('disciplineSelect', v)
											}}
											value={value || ''}
											options={
												dataDiscipline?.data
													? dataDiscipline.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Assunto' id='subjectSelect'>
							<Controller
								control={control}
								name='subjectSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => {
												setSubjectId(v?.map((val) => val.value).join(','))
												setValue('subjectSelect', v)
											}}
											value={value || ''}
											options={
												dataSubject?.data
													? dataSubject.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
					</div>
					<div className='grid grid-cols-2 gap-4 mt-4'>
						<InputGroup label='Sub Assunto' id='subSubjectSelect'>
							<Controller
								control={control}
								name='subSubjectSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('subSubjectSelect', v)}
											options={
												dataSubSubject?.data
													? dataSubSubject.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value || ''}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Ano' id='yearSelect'>
							<Controller
								control={control}
								name='yearSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('yearSelect', v)}
											options={
												dataYear?.data
													? dataYear.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value || null}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Cargo' id='occupationSelect'>
							<Controller
								control={control}
								name='occupationSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('occupationSelect', v)}
											options={
												dataOccupation?.data
													? dataOccupation.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value || null}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Banca' id='bancaSelect'>
							<Controller
								control={control}
								name='bancaSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('bancaSelect', v)}
											options={
												dataBanca?.data
													? dataBanca.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value || null}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>
						<InputGroup label='Instituição' id='institutionSelect'>
							<Controller
								control={control}
								name='institutionSelect'
								render={({
									field: { value, onBlur },
									fieldState: { isTouched, error },
								}) => {
									return (
										<Select
											onChange={(v) => setValue('institutionSelect', v)}
											options={
												dataInstitution?.data
													? dataInstitution.data.map((item) => ({
															value: item.id,
															label: item.name,
													  }))
													: []
											}
											value={value || null}
											isMulti
											onBlur={onBlur}
											styles={{
												input: (base) => ({
													...base,
													borderRadius: '6px',
													paddingLeft: '2.5px',
													paddingRight: '2.5px',
													paddingTop: '3.5px',
													paddingBottom: '3.5px',
												}),
												control: (controlStyles) => ({
													...controlStyles,
													borderColor: (() => {
														const isValid = inputIsValid(error, isTouched)

														if (typeof isValid === 'undefined') {
															return '#D1D5DB !important'
														}

														if (!isValid) {
															return '#F44545 !important'
														}

														return '#15CA71 !important'
													})(),
												}),
												option: (optionStyle, { isFocused }) => ({
													...optionStyle,
													backgroundColor: isFocused ? '#1673FA' : '#fff',
													color: isFocused ? '#fff' : '#000',
												}),
											}}
											placeholder=''
											classNamePrefix='react-select'
										/>
									)
								}}
							/>
						</InputGroup>

						<Controller
							control={control}
							name='sourceFile'
							render={({ field, fieldState }) => {
								return (
									<FileInput
										className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]'
										mt='mt-4'
										onChange={(e) => {
											field.onChange(e)
										}}
										isValid={inputIsValid(
											fieldState.error,
											fieldState.isTouched
										)}
									>
										Arquivo para a IA ler e se basear na criação de questões
									</FileInput>
								)
							}}
						/>
						<InputGroup label='Quantidade' id='quantity'>
							<Input
								type='number'
								{...register('quantity')}
								isValid={inputIsValid(errors.quantity, touchedFields.quantity)}
							/>
						</InputGroup>
						<InputGroup
							label='Informação enunciado'
							id='statementInformation'
							tooltip={{
								text: 'Informação a ser obrigatória no enunciado. Todo “x” será substituído pelo número do artigo que está sendo cobrado na questão',
							}}
						>
							<Input
								type='text'
								{...register('statementInformation')}
								isValid={inputIsValid(
									errors.statementInformation,
									touchedFields.statementInformation
								)}
							/>
						</InputGroup>
						<InputGroup label='Prompt customizado' id='customPrompt'>
							<textarea
								{...register('customPrompt')}
								className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px] resize-none h-40'
							/>
						</InputGroup>
					</div>

					<div className='flex gap-4 mt-6 mb-10'>
						<Button type='submit'>Gerar questões</Button>
					</div>
				</form>
				<Divider className={styles.divider} />
			</Template>
		</>
	)
}

export default QuestionGenerator
