import { BASE_URL } from '@constants/api'
import {
	AddPopupsRequest,
	ListPopupsRequest,
	ListPopupsResponse,
	ShowPopupsResponse,
	UpdatePopupsRequest,
} from '@models/popups.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const popupsApi = createApi({
	reducerPath: 'popupsApi',
	tagTypes: ['Popups'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListPopupsResponse, ListPopupsRequest>({
			query: ({ page, search = '' }) => ({
				url: `/popups?limit=20&page=${page}&search=${search}`,
				method: 'GET',
			}),
			providesTags: ['Popups'],
		}),
		showPopups: builder.query<ShowPopupsResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/popups/${id}`,
				method: 'GET',
			}),
			providesTags: ['Popups'],
		}),
		show: builder.mutation<ShowPopupsResponse, { id: string }>({
			query: ({ id }) => ({
				url: `/popups/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddPopupsRequest>({
			query: (data) => {
				const formData = new FormData()

				formData.append('desktopPicture', data.desktopPicture[0])
				formData.append('mobilePicture', data.mobilePicture[0])
				formData.append('accessTime', data.accessTime.toString())
				formData.append('link', data.link)
				formData.append('classId', data.classId?.toString() || '')
				formData.append('forPaidClasses', data.forPaidClasses.toString())

				return {
					url: `/popups`,
					enctype: 'multipart/form-data',
					body: formData,
					method: 'POST',
				}
			},
			invalidatesTags: ['Popups'],
		}),
		edit: builder.mutation<void, UpdatePopupsRequest>({
			query: ({ id, ...dataPopups }) => {
				const formData = new FormData()

				if (dataPopups.desktopPicture) {
					formData.append('desktopPicture', dataPopups.desktopPicture[0])
				}
				if (dataPopups.mobilePicture) {
					formData.append('mobilePicture', dataPopups.mobilePicture[0])
				}
				formData.append('accessTime', dataPopups.accessTime.toString())
				formData.append('link', dataPopups.link)
				formData.append('classId', dataPopups.classId?.toString() || '')
				formData.append('forPaidClasses', dataPopups.forPaidClasses.toString())

				return {
					url: `/popups/${id}`,
					body: formData,
					method: 'PUT',
				}
			},
			invalidatesTags: ['Popups'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `/popups/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Popups'],
		}),
	}),
})
