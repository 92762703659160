/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Fragment, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Alert from '@components/Alert'
import Error from '@components/Error'
import InputModal from '@components/InputModal'
import Logo from '@components/Logo'
import Notification from '@components/Notification'
import { DEBUG_MODE } from '@constants/api'
import { Transition } from '@headlessui/react'
import { FireIcon, UsersIcon } from '@heroicons/react/outline'
import {
	ArrowSmRightIcon,
	ChevronLeftIcon,
	HomeIcon,
} from '@heroicons/react/solid'
import { unreadedMessagesApi } from '@services/unreadedMessagesApi'
import { resetSubmenu, setSubmenuOpened } from '@store/ducks/sidebar'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import useValidateUserRole from '@utils/hooks/useValidateUserRole'
import classNames from 'classnames'
import dayjs from 'dayjs'

const styles = {
	sidebar: `min-h-screen w-[270px] ${DEBUG_MODE ? 'bg-white' : 'bg-gray-200'}`,
	items: 'flex flex-col flex-grow pt-6 ',
	logo: 'flex justify-center items-center mb-2',
	navContainer: 'flex-1 flex flex-col',
	nav: 'flex-1 ',
	link: 'w-[230px] h-[36px] mx-5 my-2 pt-2 pl-2 flex flex-row justify-between items-stretch font-300 rounded-md text-[15px]',
	linkSubmenu:
		'w-[230px] h-[36px] mx-5 my-2 pt-2 pl-2 flex flex-row justify-start items-stretch font-300 rounded-md text-sm',
	defaultLink:
		'text-gray-500 hover:bg-gray-100 hover:font-500 hover:text-gray-700',
	currentLink: 'bg-primary text-white',
	iconLink: ' h-5 w-5 mb-2 rounded-full mx-2 self-center',
	animatiomSubmenu: 'transition-transform',
}

export default function Sidebar() {
	const dispatch = useAppDispatch()
	const { showSubmenus } = useAppSelector((state) => state.sidebar)

	const location = useLocation()
	const year = dayjs().year()
	const month = dayjs().month() + 1
	const {
		auth: { user },
	} = useAppSelector((state) => state)

	function isCurrent(pathname: string): boolean {
		if (pathname === '/') {
			return location.pathname === pathname
		}

		return location.pathname.split('/').filter(Boolean)[0] === pathname
	}

	const showAdmin = useValidateUserRole('Admin')

	const showAdminContent = useValidateUserRole('Admin', 'Content Creator')

	const showAdminQuestion = useValidateUserRole('Admin', 'Question Creator')

	const showAll = useValidateUserRole(
		'Admin',
		'Content Creator',
		'Question Creator'
	)

	type NavigationItem = {
		name: string
		href?: string
		icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
		current?: boolean
		show: boolean
		isExternal?: boolean
		children?: NavigationItem[]
	}

	const navigation: NavigationItem[] = [
		{
			name: 'Dashboard',
			href: '/',
			icon: HomeIcon,
			current: isCurrent('/'),
			show: showAdmin,
		},
		{
			name: 'Filtros',
			icon: ChevronLeftIcon,
			children: [
				{
					name: 'Disciplina',
					href: '/disciplina',
					icon: ArrowSmRightIcon,
					current: isCurrent('disciplina'),
					show: showAdminQuestion,
				},
				{
					name: 'Banca',
					href: '/banca',
					icon: ArrowSmRightIcon,
					current: isCurrent('banca'),
					show: showAll,
				},
				{
					name: 'Instituição',
					href: '/instituicao',
					icon: ArrowSmRightIcon,
					current: isCurrent('instituicao'),
					show: showAll,
				},
				{
					name: 'Ano',
					href: '/ano',
					icon: ArrowSmRightIcon,
					current: isCurrent('ano'),
					show: showAll,
				},
				{
					name: 'Cargo',
					href: '/cargo',
					icon: ArrowSmRightIcon,
					current: isCurrent('cargo'),
					show: showAll,
				},
			],
			show: showAll,
		},
		{
			name: 'Turma',
			icon: ChevronLeftIcon,
			children: [
				{
					name: 'Turmas',
					href: '/turmas',
					icon: ArrowSmRightIcon,
					current: isCurrent('turmas'),
					show: showAdminContent,
				},
				{
					name: 'Módulos',
					href: '/modulos',
					icon: ArrowSmRightIcon,
					current: isCurrent('modulos'),
					show: showAdminContent,
				},
				{
					name: 'Bibliotecas',
					href: '/bibliotecas',
					icon: ArrowSmRightIcon,
					current: isCurrent('bibliotecas'),
					show: showAdminContent,
				},
				{
					name: 'Popups',
					href: '/popups',
					icon: ArrowSmRightIcon,
					current: isCurrent('popups'),
					show: showAdmin,
				},
				{
					name: 'Comentários Módulo',
					href: '/comentarios-modulo',
					icon: ArrowSmRightIcon,
					current: isCurrent('comentarios-modulo'),
					show: showAdmin,
				},
				{
					name: 'Comentários Questões',
					href: '/comentarios-questoes',
					icon: ArrowSmRightIcon,
					current: isCurrent('comentarios-questoes'),
					show: showAdmin,
				},
				{
					name: 'Erros Módulo',
					href: '/erros-modulo',
					icon: ArrowSmRightIcon,
					current: isCurrent('erros-modulo'),
					show: showAdmin,
				},
				{
					name: 'Erros Questões',
					href: '/erros-questoes',
					icon: ArrowSmRightIcon,
					current: isCurrent('erros-questoes'),
					show: showAdmin,
				},
			],
			show: showAdminContent,
		},
		{
			name: 'Questionário',
			icon: ChevronLeftIcon,
			children: [
				{
					name: 'Simulado',
					href: '/categorias',
					icon: ArrowSmRightIcon,
					current: isCurrent('categorias'),
					show: showAdminContent,
				},
				{
					name: 'Questões',
					href: '/questoes',
					icon: ArrowSmRightIcon,
					current: isCurrent('questoes'),
					show: showAll,
				},
				{
					name: 'Relatório',
					href: '/questoes-relatorio',
					icon: ArrowSmRightIcon,
					current: isCurrent('questoes-relatorio'),
					show: showAdmin,
				},
			],
			show: showAll,
		},
		{
			name: 'Comunicados',
			icon: ChevronLeftIcon,
			children: [
				{
					name: 'Comunicados',
					href: '/comunicados',
					icon: ArrowSmRightIcon,
					current: isCurrent('comunicados'),
					show: showAdmin,
				},
			],
			show: showAdmin,
		},
		{
			name: 'IA',
			icon: ChevronLeftIcon,
			children: [
				{
					name: 'Gerador de Questões',
					href: '/gerador-questoes',
					icon: ArrowSmRightIcon,
					current: isCurrent('gerador-questões'),
					show: showAdmin,
				},
				{
					name: 'Revisar Questões',
					href: '/revisar-questoes',
					icon: ArrowSmRightIcon,
					current: isCurrent('revisar-questões'),
					show: showAdmin,
				},
			],
			show: showAll,
		},
		{
			name: 'BeTalent',
			icon: ChevronLeftIcon,
			children: [
				{
					name: 'Controle de Horas',
					href: `https://metabase.betalent.tech/dashboard/11-controle-de-horas?ano=${year}&m%25C3%25AAs=${month}`,
					icon: ArrowSmRightIcon,
					current: isCurrent('betalent-control-hours'),
					show: showAdmin,
					isExternal: true,
				},
				{
					name: 'Dashboard BI',
					href: 'https://metabase.betalent.tech/dashboard/4-dashboard-usuarios-dls',
					icon: ArrowSmRightIcon,
					current: isCurrent('betalent-bi'),
					show: showAdmin,
					isExternal: true,
				},
			],
			show: showAdmin,
		},
		{
			name: 'Página Promocional',
			href: '/promocional',
			icon: FireIcon,
			current: isCurrent('promocional'),
			show: showAdmin,
		},
		{
			name: 'Leads',
			href: '/leads',
			icon: FireIcon,
			current: isCurrent('leads'),
			show: showAdmin,
		},
		{
			name: 'Leads Popups',
			href: '/leads-popups',
			icon: FireIcon,
			current: isCurrent('leadsPopups'),
			show: showAdmin,
		},
		{
			name: 'Clientes',
			href: '/clientes',
			icon: UsersIcon,
			current: isCurrent('clientes'),
			show: showAdmin,
		},
		{
			name: 'Usuários',
			href: '/usuarios',
			icon: UsersIcon,
			current: isCurrent('usuarios'),
			show: showAdmin,
		},
		{
			name: 'Profile',
			href: '/profile',
			icon: UsersIcon,
			current: isCurrent('profile'),
			show: showAll,
		},
		{
			name: 'Status das Migrações',
			href: '/statusMigrations',
			icon: UsersIcon,
			current: isCurrent('statusMigrations'),
			show: showAdmin,
		},
	]

	let filterNavigationChindrens = navigation
		.filter((nav) => nav?.children)
		.map((child) => {
			return child?.children?.find((c) => c.current) ? child : [undefined]
		})

	const [getUunreadedMessages, { data }] = unreadedMessagesApi.useShowMutation()

	const totalModuleComments = data?.totalModuleComments || false
	const totalModuleErrors = data?.totalModuleErrors || false
	const totalQuestionComments = data?.totalQuestionComments || false
	const totalQuestionErrors = data?.totalQuestionErrors || false

	useEffect(() => {
		if (showAdmin) {
			getUunreadedMessages({})
		}
	}, [getUunreadedMessages, showAdmin])

	return (
		<div className={styles.sidebar}>
			<div className={styles.items}>
				<div className={`${styles.logo} ${DEBUG_MODE ? 'flex-col' : ''}`}>
					{DEBUG_MODE ? (
						<>
							<p className='text-dark font-bold text-lg'>DEBUG</p>
							<p className='text-dark font-bold text-lg'>MODE</p>
						</>
					) : (
						<Link to='/'>
							<Logo />
						</Link>
					)}
				</div>

				<div className='overflow-y-auto max-h-screen sidebar-scroll sidebar-scroll-not-width'>
					<div className={styles.navContainer}>
						<nav className={styles.nav}>
							{navigation?.map((item, key) => {
								if (!item.show) return <Fragment key={item.href} />

								return (
									<div key={item.name}>
										<Link
											to={item.href || ''}
											onClick={() => {
												!item?.href
													? item?.children &&
													  dispatch(setSubmenuOpened({ type: key }))
													: dispatch(resetSubmenu())
												filterNavigationChindrens = []
											}}
											className={classNames(
												item.current ? styles.currentLink : styles.defaultLink,
												styles.link
											)}
										>
											{item.name}
											{(() => {
												if (item.name === 'Perfil') {
													return user?.imageUrl ? (
														<img
															src={user.imageUrl}
															alt=''
															className={styles.iconLink}
															referrerPolicy='no-referrer'
														/>
													) : (
														<item.icon
															className={styles.iconLink}
															aria-hidden='true'
														/>
													)
												}

												return (
													<item.icon
														className={classNames(
															showSubmenus.includes(key) ? '-rotate-90' : '',
															styles.iconLink,
															styles.animatiomSubmenu,
															'duration-150'
														)}
														aria-hidden='true'
													/>
												)
											})()}
										</Link>

										<Transition
											show={
												showSubmenus.length
													? showSubmenus.includes(key)
													: !filterNavigationChindrens[0] &&
													  item === filterNavigationChindrens[0]
											}
											enter='transition-all ease-in-out duration-300'
											enterFrom='max-h-0 opacity-0'
											enterTo='max-h-fit opacity-100'
											leave='transition-all ease-out'
											leaveFrom='opacity-100'
											leaveTo='opacity-0'
										>
											{item.children?.map((child) => {
												if (!child.show) return <Fragment key={child.href} />
												const renderChildren = () => {
													return (
														<>
															<child.icon
																className={styles.iconLink}
																aria-hidden='true'
															/>
															{child.name}
															{child.name === 'Comentários Módulo' &&
																totalModuleComments && (
																	<p className='comments-not-read'>
																		{totalModuleComments}
																	</p>
																)}
															{child.name === 'Comentários Questões' &&
																totalQuestionComments && (
																	<p className='comments-not-read'>
																		{totalQuestionComments}
																	</p>
																)}
															{child.name === 'Erros Módulo' &&
																totalModuleErrors && (
																	<p className='comments-not-read'>
																		{totalModuleErrors}
																	</p>
																)}
															{child.name === 'Erros Questões' &&
																totalQuestionErrors && (
																	<p className='comments-not-read'>
																		{totalQuestionErrors}
																	</p>
																)}
														</>
													)
												}

												return (
													<Transition.Child
														key={child.name}
														enter='transition-transform ease-in duration-300'
														enterFrom='-translate-x-2'
														enterTo='translate-x-0'
														leave='transition-transform ease-out'
														leaveFrom='translate-x-0'
														leaveTo='-translate-x-2'
													>
														{child.isExternal ? (
															<a
																href={child.href}
																target='_blank'
																className={classNames(
																	child.current
																		? styles.currentLink
																		: styles.defaultLink,
																	styles.linkSubmenu
																)}
																rel='noreferrer'
															>
																{renderChildren()}
															</a>
														) : (
															<Link
																// biome-ignore lint/style/noNonNullAssertion: <explanation>
																to={child.href!}
																className={classNames(
																	child.current
																		? styles.currentLink
																		: styles.defaultLink,
																	styles.linkSubmenu
																)}
															>
																{renderChildren()}
															</Link>
														)}
													</Transition.Child>
												)
											})}
										</Transition>
									</div>
								)
							})}
						</nav>
					</div>
				</div>
			</div>

			<Alert />
			<InputModal />
			<Error />
			<Notification />
		</div>
	)
}
