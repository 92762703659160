import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	Template,
	PageTitle,
	InputGroup,
	Input,
	Button,
	Table,
	Tr,
	Td,
	Pagination,
	Flex,
	SelectInput,
	Divider,
	Breadcrumb,
} from '@components'
import EmptyRow from '@components/EmptyRow'
import Spinner from '@components/Spinner'
import { leadApi, useExportLeadsPopupsMutation } from '@services/LeadsApi'
import { popupsApi } from '@services/PopupsApi'
import Formatters from '@utils/helpers/Formatters'
import { useQuery } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] mt-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function LeadsPopups() {
	const pages = [{ name: 'LeadsPopup', href: '#', current: true }]

	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1

	const [startDate, setStartDate] = useState(query.get('start_date') || '')
	const [endDate, setEndDate] = useState(query.get('start_date') || '')
	const [popupType, setPopupType] = useState('')
	const [page, setPage] = useState(pageQuery)

	const [exportLeadsPopups] = useExportLeadsPopupsMutation()

	const handleExport = async () => {
		try {
			const blob = await exportLeadsPopups({ startDate, endDate }).unwrap()
			const link = document.createElement('a')

			link.href = window.URL.createObjectURL(blob)
			link.download = `export_${startDate}_to_${endDate}.csv`
			link.click()
		} catch (error) {
			//
		}
	}

	const { data: popupsData, isLoading: isLoadingPopups } =
		popupsApi.useListQuery({
			page: 1,
			search: '',
		})

	const { data, isLoading } = leadApi.useListLeadsPopupsQuery({
		page,
		startDate,
		endDate,
		popupId: popupType,
	})

	const searchCallback = useCallback(() => {
		navigate('/leads-popups')
	}, [navigate])

	useEffect(() => {
		searchCallback()
	}, [startDate, searchCallback])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	return (
		<Template>
			<PageTitle title='Leads Popups' />
			<Breadcrumb pages={pages} />
			<Flex className={styles.searchContainer}>
				<InputGroup label='Data inicial' id='startDate'>
					<Input
						type='date'
						value={startDate}
						onChange={(e) => setStartDate(e.currentTarget.value)}
					/>
				</InputGroup>
				<InputGroup label='Data final' id='endDate'>
					<Input
						type='date'
						value={endDate}
						onChange={(e) => setEndDate(e.currentTarget.value)}
					/>
				</InputGroup>
				<InputGroup label='Popup' id='popupType' className='col-span-2'>
					{isLoadingPopups ? (
						<Spinner size='sm' />
					) : (
						<SelectInput
							btnClass='py-[8px] px-[13px] w-[300px]'
							options={[
								{ value: 0, option: 'Todos' },
								...(popupsData?.data
									? popupsData.data
											.filter((popup) => popup.class?.name)
											.map((popup) => ({
												value: popup.id,
												option: popup.class.name,
												key: `${popup.id}-${popup.class.name}`,
											}))
									: []),
							]}
							onChange={(option) => setPopupType(String(option.value))}
						/>
					)}
				</InputGroup>

				<Button onClick={handleExport}>Exportar</Button>
			</Flex>
			{isLoading ? (
				<div className='flex justify-center'>
					<Spinner size='md' />
				</div>
			) : (
				<Table
					th={[
						{ label: 'Nome' },
						{ label: 'Email' },
						{ label: 'Telefone' },
						{ label: 'Popup' },
						{ label: 'Gratuito/Pago' },
						{ label: 'Clicou Em' },
					]}
				>
					{data?.data && data?.data?.length > 0 ? (
						data?.data.map(
							({ id: userId, name, email, phone, clickDate, classe, mode }) => (
								<Tr key={userId || `${name}-${email}`}>
									<Td className='w-[40%] px-6'>
										<Flex className='items-center gap-4'>{name}</Flex>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>{email}</Flex>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>{phone || 'NA'}</Flex>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>{classe}</Flex>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>{mode}</Flex>
									</Td>
									<Td>
										<Flex className='items-center gap-4'>
											{Formatters.createdAt(clickDate)}
										</Flex>
									</Td>
								</Tr>
							)
						)
					) : (
						<EmptyRow tdAmount={3} message='Nenhum usuário encontrado' />
					)}
				</Table>
			)}
			<Divider className={styles.divider} />
			{data?.data && data?.data?.length > 0 && (
				<Pagination
					pageCount={data?.meta.lastPage || 0}
					maxAmount={data?.meta.total || 0}
					perPage={data?.meta.perPage || 0}
				/>
			)}
		</Template>
	)
}

export default LeadsPopups
