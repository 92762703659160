import { BASE_URL } from '@constants/api'
import {
	AddPromotionalLP,
	EditPromotionalLP,
	ListRequest,
	ListResponse,
	PromotionalLP,
} from '@models/promotionalLP.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const promotionalApi = createApi({
	reducerPath: 'promotionalApi',
	tagTypes: ['PromotionalsLP', 'LP'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListResponse, ListRequest>({
			query: ({ page, search = '' }) => ({
				url: `/promotional/lps?limit=20&page=${page}&search=${search}`,
				method: 'GET',
			}),
			providesTags: ['PromotionalsLP'],
		}),
		showPromotional: builder.query<PromotionalLP, { id: number }>({
			query: ({ id }) => ({
				url: `/promotional/lps/${id}`,
				method: 'GET',
			}),
			providesTags: ['PromotionalsLP'],
		}),
		add: builder.mutation<void, AddPromotionalLP>({
			query: ({
				promotionalTitle,
				promotionalText,
				promotionalSubtitle,
				promotionalImage,
				promotionalRdStationTag,
				promotionalButtonText,
				classId,
			}) => {
				const formData = new FormData()

				formData.append('promotionalTitle', promotionalTitle)
				formData.append('promotionalText', promotionalText)
				formData.append('promotionalSubtitle', promotionalSubtitle)
				formData.append('promotionalButtonText', promotionalButtonText)
				formData.append('promotionalRdStationTag', promotionalRdStationTag)
				formData.append('classId', classId.toString())
				formData.append(
					'promotionalImage',
					promotionalImage ? promotionalImage[0] : ''
				)

				return {
					url: '/promotional/lps',
					body: formData,
					method: 'POST',
				}
			},
			invalidatesTags: ['PromotionalsLP'],
		}),
		edit: builder.mutation<void, EditPromotionalLP>({
			query: ({
				promotionalTitle,
				promotionalText,
				promotionalSubtitle,
				promotionalImage,
				promotionalButtonText,
				promotionalRdStationTag,
				classId,
				id,
			}) => {
				const formData = new FormData()

				formData.append('promotionalTitle', promotionalTitle)
				formData.append('promotionalText', promotionalText)
				formData.append('promotionalSubtitle', promotionalSubtitle)
				formData.append('promotionalButtonText', promotionalButtonText)
				formData.append('promotionalRdStationTag', promotionalRdStationTag)
				formData.append('classId', classId.toString())
				formData.append(
					'promotionalImage',
					promotionalImage ? promotionalImage[0] : ''
				)

				return {
					url: `/promotional/lps/${id}`,
					body: formData,
					method: 'PUT',
				}
			},
			invalidatesTags: ['PromotionalsLP'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/promotional/lps/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['PromotionalsLP'],
		}),
		activate: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/promotional/activate/lps/${id}`,
				method: 'POST',
			}),
			invalidatesTags: ['PromotionalsLP'],
		}),
		getActive: builder.query<PromotionalLP, void>({
			query: () => ({
				url: '/promotional/lp',
				method: 'GET',
			}),
			providesTags: ['PromotionalsLP'],
		}),
	}),
})
